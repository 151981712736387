import React, { useState, useEffect, useRef } from "react";
import "../Assets/Styles/Pharaphraser.css";
import { Alert, CircularProgress } from "@mui/material";
import OpenAI from "openai";
import { useLocation, useNavigate } from "react-router-dom";
import "react-dropdown/style.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@mui/material/Select";
import EngImg from "../Assets/Icons/EngImg.png";
import FrnchImg from "../Assets/Icons/FrnchImg.jpg";
import SpnshImg from "../Assets/Icons/SpnshImg.png";
import GrmnImg from "../Assets/Icons/GrmnImg.png";
import SVG from "../Assets/Icons/Vector.svg";
import { saveAs } from "file-saver";
import CreditAlert from "../Components/CreditAlert";
import { ref, update } from "@firebase/database";
import { auth, db } from "../Config/FirebaseConfig";

const Humanizer = ({ logedUser, OPENAI_API_KEY, chargeRate, openAiKey }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tone, setTone] = useState("descriptive");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const textAreaRef = useRef();
  let creditsUsed = 0;

  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const [result, setResult] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    if (location.state !== null) {
      setPrompt(location?.state?.prompt);
      setIsButtonVisible(false);
    }
  }, []);

  //this function clears the textarea
  function deleteButton() {
    setResult("");
    setPrompt("");
    setIsButtonVisible(true);
  }

  //this function paste the text in clipboard
  const handleClick = async () => {
    const clipboardText = await navigator.clipboard.readText();
    if (clipboardText) {
      setPrompt(clipboardText);
      setIsButtonVisible(false);
      //setTypedText(true)
    }
  };

  //check user available credits
  const userCredits = logedUser?.balance;

  const deductCredits = () => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed;
      update(ref(db, `Users/${auth.currentUser.uid}`), {
        // firebase function to update the user in the database
        balance: newBalance,
      });
    } else {
      setInsufficientCredits(true);
      // alert('insufficient credits')
    }
  };

  //this is where pharaphrsing happens
  const humanize = () => {
    //const promptt =  `Original text: ${prompt}\n\nrephrase the text to avoid plagiarism in a ${tone} tone and in ${selectedLanguage} and maintain word count`
    setResult("");
    setError("");
    if (prompt !== "") {
      if (prompt.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        setLoading(true);
        //check user available credits
        if (userCredits >= prompt.split(" ").length * chargeRate) {
          setInsufficientCredits(false);
          //this checks if a tone is not selected
          if (tone === "" && selectedLanguage !== "") {
            openai.chat.completions
              .create({
                model: "gpt-4-1106-preview",
                messages: [
                  {
                    role: "user",
                    content: `Humanize the text: ${prompt} to sound more natural.`,
                    // content: `rephrase ${prompt} to avoid plagiarism in ${selectedLanguage} and maintain word count`,
                  },
                ],
                //prompt: `Original text: ${prompt}\n\nrephrase the text to avoid plagiarism in ${selectedLanguage} and maintain word count`,
                temperature: 0.5,
                max_tokens: 4000,
                top_p: 1,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
                format: "html",
              })
              .then((response) => {
                creditsUsed = response.usage.total_tokens * chargeRate;
                deductCredits();
                setResult(response.choices[0].message.content);
                setLoading(false);
              })
              .catch((err) => {
                if (err.message === "Network Error") {
                  setError("Check Your Connecton");
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 429"
                ) {
                  setError(
                    "Oops! something went wrong with your requests, We are working on it..  Try again later"
                  );
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 400"
                ) {
                  setError(
                    "You exceeded the word per request. Please reduce your words to 1500 or less"
                  );
                  setLoading(false);
                } else {
                  setError(err.message);
                  setLoading(false);
                }
              });
          } else {
            ///this function trigers if a tone is selected
            openai.chat.completions
              .create({
                model: "gpt-4-1106-preview",
                messages: [
                  {
                    role: "user",
                    content: `Humanize the text: ${prompt} to sound more natural.`,
                    // content: `rephrase ${prompt} to avoid plagiarism in a ${tone} tone and in ${selectedLanguage} and maintain word count`,
                  },
                ],
                //prompt: `Original text: ${prompt}\n\nrephrase the text to avoid plagiarism in a ${tone} tone and in ${selectedLanguage} and maintain word count`,
                temperature: 0.5,
                max_tokens: 4000,
                top_p: 1,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
              })
              .then((response) => {
                creditsUsed = response.usage.total_tokens * chargeRate;
                deductCredits();
                setResult(response.choices[0].message.content);
                setLoading(false);
              })
              .catch((err) => {
                if (err.message === "Network Error") {
                  setError("Check Your Connecton");
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 429"
                ) {
                  setError(
                    "Oops! something went wrong with your requests, We are working on it..  Try again later"
                  );
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 400"
                ) {
                  setError(
                    "You exceeded the word per request. Please reduce your words to 700 or less"
                  );
                  setLoading(false);
                } else {
                  setError(err.message);
                  setLoading(false);
                }
              });
          }
        } else {
          setInsufficientCredits(true);
          setLoading(false);
        }
      }
    } else {
      setError("type the text to humanize");
    }
  };

  //this function copies text to the clipboard

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(result);
      alert("Text copied");
    } catch (err) {
      alert("Failed to copy: ", err.message);
    }
  };

  //this function downloads text to storage
  const randomNumber = Math.random() * 100;
  function downloadStringAsFile() {
    const blob = new Blob([result.trim()], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, randomNumber);
  }

  //THESE FUNCTIONS REDIRECT USERS
  const redirectToSummarizer = () => {
    navigate("/summarizer", { state: { prompt: result } });
  };
  const redirectToPlagcheck = () => {
    navigate("/check-plagiarism", { state: { prompt: result } });
  };
  const redirectToGrammaCheck = () => {
    navigate("/check-grammar", { state: { prompt: result } });
  };

  //the functions below gets doc from device and read it
  const fileInputRef = useRef();

  function handleButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  }

  function handleFileRead(event) {
    const content = event.target.result;
    setPrompt(content);
    setIsButtonVisible(false);
  }

  //this functions sets language to selected language on drop down
  const handleChangeLanguage = (e) => {
    setSelectedLanguage(e.target.value);
  };

  //this function count words in the textarea
  function countWords() {
    return prompt.split(" ").length;
  }
  //this function count result words
  function countResultWords() {
    return result.split(" ").length;
  }

  const handleChangeTone = (e) => {
    setTone(e.target.value);
  };

  const [selectedButton, setSelectedButton] = useState("paraphraser");

  const handleChangeButton = (e) => {
    setSelectedButton(e.target.value);
  };

  const handleTextChange = (e) => {
    setPrompt(e.target.value);
    if (e.target.value.split(" ").length > 1500) {
      setError(
        "You exceeded the limit of words per request. Please reduce your words to 1500 or less"
      );
    } else {
      setError("");
    }
  };

  const Error = () => {
    // Error Component
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };

  return (
    <>
      <div className="second-header-cont">
        <FormControl className="second-header">
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedButton}
            onChange={handleChangeButton}
            className="select"
            style={{ color: "#fff" }}
          >
            <MenuItem className="select-item" value={"paraphraser"}>
              Humanizer
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"grammachecker"}
              onClick={redirectToGrammaCheck}
            >
              Grammar Checker
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"plagiarismchecker"}
              onClick={redirectToPlagcheck}
            >
              Plagiarism Checker
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"summarizer"}
              onClick={redirectToSummarizer}
            >
              Summarizer
            </MenuItem>
            {/* <MenuItem className="select-item" value={'contentwriter'}>Content Writer</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className="main">
        <div className="cont-header">
          <img src={SVG} alt="trash icon" onClick={deleteButton} />

          {/* <div className='tones'>
          <h2 className='tone'>Tone: </h2>
          <a href='#' className='tone-btn' onClick={() => setTone('descriptive')}>Descriptive</a>
          <a href='#' className='tone-btn' onClick={() => setTone('formal')}>Formal</a>
          <a href='#' className='tone-btn' onClick={() => setTone('casual')}>Casual</a>
          <a href='#' className='tone-btn' onClick={() => setTone('informative')}>Informative</a>
          <a href='#' className='tone-btn' onClick={() => setTone('objective')}>Objective</a>
        </div> */}
          {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <FormControl className='tones-form' style={{width: '50%', border: 'none', outline: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
          <Select
            variant='standard'
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedLanguage}
            onChange={handleChangeLanguage}
            className="select-tone"
            style={{border: 'none', padding: '12px',}}
          >
            <MenuItem className="select-item" value={'English'}><img src={EngImg} alt='Eng' className='lang-img'/> English</MenuItem>
            <MenuItem className="select-item" value={'French'}><img src={FrnchImg} alt='Grmn' className='lang-img'/>French</MenuItem>
            <MenuItem className="select-item" value={'Spanish'}><img src={SpnshImg} alt='Spnsh' className='lang-img'/>Spanish</MenuItem>
            <MenuItem className="select-item" value={'German'}><img src={GrmnImg} alt='Frnch' className='lang-img'/>German</MenuItem>
          </Select>
        </FormControl>
        <img src={SVG} alt="trash icon" onClick={deleteButton}/> 
        </div>   */}
        </div>
        <div className="mobile-tones">
          {/* <FormControl
            className="tones-form"
            style={{
              border: "none",
              outline: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="tone2">Tone: </h2>
            <Select
              variant="standard"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={tone}
              onChange={handleChangeTone}
              className="select-tone"
              style={{ border: "none", padding: "12px 0" }}
            >
              <MenuItem className="select-item" value={"descriptive"}>
                Descriptive
              </MenuItem>
              <MenuItem className="select-item" value={"formal"}>
                Formal
              </MenuItem>
              <MenuItem className="select-item" value={"casual"}>
                Casual
              </MenuItem>
              <MenuItem className="select-item" value={"informative"}>
                Informative
              </MenuItem>
              <MenuItem className="select-item" value={"objective"}>
                Objective
              </MenuItem>
            </Select>
          </FormControl> */}
          {/* <FormControl
            className="tones-form"
            style={{
              border: "none",
              outline: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              variant="standard"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedLanguage}
              onChange={handleChangeLanguage}
              className="select-tone"
              style={{ border: "none", padding: "12px 0" }}
            >
              <MenuItem className="select-item" value={"English"}>
                <img src={EngImg} alt="Eng" className="lang-img" /> English
              </MenuItem>
              <MenuItem className="select-item" value={"French"}>
                <img src={FrnchImg} alt="Grmn" className="lang-img" />
                French
              </MenuItem>
              <MenuItem className="select-item" value={"Spanish"}>
                <img src={SpnshImg} alt="Spnsh" className="lang-img" />
                Spanish
              </MenuItem>
              <MenuItem className="select-item" value={"Germain"}>
                <img src={GrmnImg} alt="Frnch" className="lang-img" />
                Germain
              </MenuItem>
            </Select>
          </FormControl> */}
          <i className="bi bi-trash3 trash-icon" onClick={deleteButton}></i>
        </div>
        <div className="body">
          {insufficientCredits ? (
            <CreditAlert
              logedUser={logedUser}
              setInsufficientCredits={setInsufficientCredits}
            />
          ) : (
            <>
              <div className="query">
                <Error />
                <div className="texts">
                  {isButtonVisible && (
                    <div>
                      <textarea
                        placeholder="Start by writing or pasting your text here, then click humanize"
                        style={{
                          width: "100%",
                          minHeight: "249px",
                          maxHeight: "240px",
                          border: "none",
                          outline: "none",
                        }}
                        onChange={handleTextChange}
                      ></textarea>
                      {prompt === "" && (
                        <button className="paste-btn" onClick={handleClick}>
                          <i className="bi bi-clipboard" />
                          <h5>Paste Text</h5>
                        </button>
                      )}
                    </div>
                  )}
                  {!isButtonVisible && (
                    <textarea
                      ref={textAreaRef}
                      onChange={handleTextChange}
                      value={prompt.trim()}
                      style={{
                        width: "100%",
                        minHeight: "320px",
                        maxHeight: "350px",
                        border: "none",
                        outline: "none",
                      }}
                      id="textArea"
                    />
                  )}
                </div>
                <div className="buttons">
                  {prompt === "" ? (
                    <button className="upload-btn">
                      <i
                        className="bi bi-file-earmark-arrow-up"
                        onClick={handleButtonClick}
                      />
                      <h5 onClick={handleButtonClick}>Upload Doc</h5>
                      <input
                        type="file"
                        accept=".txt"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        style={{ display: "none" }}
                      />
                    </button>
                  ) : (
                    <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
                  )}
                  <button className="pharaphrase-btn" onClick={humanize}>
                    Humanize
                  </button>
                </div>
              </div>
              <div className="wordcount-div">
                <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
              </div>
              <div className="result">
                {result === "" ? (
                  <div className="empty-result">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                  </div>
                ) : (
                  <div className="result-ok">
                    <div className="result-text">
                      <textarea
                        readOnly
                        className="result-text"
                        // onChange={(e) => setPrompt(e.target.value)}
                        value={result.trim()}
                        style={{
                          cursor: "text",
                          width: "100%",
                          minHeight: "320px",
                          maxHeight: "350px",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className="paraph-result-actions">
                      <p className="result-length" style={{ display: "none" }}>
                        {countResultWords()} words
                      </p>
                      <div className="result-buttons">
                        {/* <img alt='img'/> */}
                        {/* <button
                          className="result-btn summarize-result"
                          onClick={redirectToSummarizer}
                        >
                          Summarize
                        </button> */}
                        <button
                          className="result-btn check-result"
                          onClick={redirectToPlagcheck}
                        >
                          Plagiarism
                        </button>
                        {/* <button
                          className="result-btn gramma-result"
                          onClick={redirectToGrammaCheck}
                        >
                          Grammar
                        </button> */}
                      </div>
                      <div className="result-todo">
                        <i
                          className="bi bi-download download-result"
                          onClick={downloadStringAsFile}
                        />
                        <i
                          className="bi bi-clipboard copy-result"
                          onClick={copyContent}
                        />
                      </div>
                    </div>
                    {result !== "" && (
                      <div className="mobile-result-todo">
                        <h4 className="word-count">
                          {" "}
                          {countResultWords()} Words
                        </h4>
                        <button className="rephrase-btn" onClick={humanize}>
                          Humanize
                        </button>
                        <div className="mobile-result-actions">
                          <i
                            className="bi bi-download download-result"
                            onClick={downloadStringAsFile}
                          />
                          <i
                            className="bi bi-clipboard copy-result"
                            onClick={copyContent}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {result === "" && (
                <button className="btn2" onClick={humanize}>
                  Humanize
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {loading && (
        <div className="loader-page">
          <CircularProgress className="loader" />
          <p>
            Making the magic... <br />
            Please Wait
          </p>
        </div>
      )}
    </>
  );
};

export default Humanizer;

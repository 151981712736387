import { Alert, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Assets/Styles/Summarizer.css";
import OpenAI from "openai";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@mui/material/Select";
import { saveAs } from "file-saver";
import CreditAlert from "../Components/CreditAlert";
import { ref, update } from "@firebase/database";
import { auth, db } from "../Config/FirebaseConfig";

const Summarizer = ({ logedUser, OPENAI_API_KEY, chargeRate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const userCredits = logedUser?.balance;
  let creditsUsed = 0;

  const configuration = {
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  };
  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });
  const [result, setResult] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [modal, setModal] = useState("original");
  const [error, setError] = useState("");

  useEffect(() => {
    if (location.state !== null) {
      setPrompt(location.state.prompt);
      setIsButtonVisible("false");
    }
  }, []);

  const deductCredits = () => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed;
      update(ref(db, `Users/${auth.currentUser.uid}`), {
        // firebase function to update the user balance in the database
        balance: newBalance,
      });
    } else {
      alert("insufficient credits");
    }
  };

  const summarize = () => {
    setError("");
    if (prompt !== "") {
      if (prompt.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        setLoading(true);
        if (userCredits >= prompt.split(" ").length * chargeRate) {
          openai.chat.completions
            .create({
              model: "gpt-4-1106-preview",
              messages: [
                { role: "user", content: `Summarize this: ${prompt}` },
              ],
              //prompt: `Summarize this: ${prompt}`,
              temperature: 0.5,
              max_tokens: 4000,
              top_p: 1,
              frequency_penalty: 0.0,
              presence_penalty: 0.0,
            })
            .then((response) => {
              creditsUsed = response.usage.total_tokens * chargeRate;
              deductCredits();
              setResult(response.choices[0].message.content);
              setLoading(false);
            })
            .catch((err) => {
              if (err.message === "Network Error") {
                setError("Check Your Connecton");
                setLoading(false);
              } else if (
                err.message === "Request failed with status code 429"
              ) {
                setError(
                  "Oops! something went wrong with your requests, We are working on it..  Try again later"
                );
                setLoading(false);
              } else if (
                err.message === "Request failed with status code 400"
              ) {
                setError(
                  "You exceeded the word per request. Please reduce your words to 1500 or less"
                );
                setLoading(false);
              } else {
                setError(err.message);
                setLoading(false);
              }
            });
        } else {
          setInsufficientCredits(true);
          setLoading(false);
        }
      }
    } else {
      setError("type the text to summarize");
    }
  };

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(result);
      alert("Text copied");
    } catch (err) {
      alert("Failed to copy: ", err.message);
    }
  };

  //this function downloads text to storage
  const randomNumber = Math.random() * 100;
  function downloadStringAsFile() {
    const blob = new Blob([result.trim()], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, randomNumber);
  }

  //THESE FUNCTIONS REDIRECT USERS
  const redirectToPharaphraser = () => {
    navigate("/paraphraser", { state: { prompt: result } });
  };
  const redirectToPlagcheck = () => {
    navigate("/check-plagiarism", { state: { prompt: result } });
  };
  const redirectToGrammaCheck = () => {
    navigate("/check-grammar", { state: { prompt: result } });
  };

  //this function paste the text in clipboard
  const handleClick = async () => {
    const clipboardText = await navigator.clipboard.readText();
    if (clipboardText) {
      setPrompt(clipboardText);
      setIsButtonVisible(false);
    }
  };

  //this function count words in the textarea
  function countWords() {
    return prompt.split(" ").length;
  }

  //this function count words in the result
  function resultWordCounts() {
    return result.split(" ").length;
  }

  const [selectedButton, setSelectedButton] = useState("summarizer");

  const handleChangeButton = (e) => {
    setSelectedButton(e.target.value);
  };

  //this function summarizes the text in paraghraphs
  const summarizeParagraph = () => {
    setError("");
    if (prompt !== "") {
      if (prompt.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        setLoading(true);
        openai.chat.completions
          .create({
            model: "gpt-4-1106-preview",
            messages: [{ role: "user", content: `Summarize this: ${prompt}` }],
            //prompt: `Summarise this text in paragraph: ${prompt}`,
            temperature: 0.5,
            max_tokens: 4000,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
          })
          .then((response) => {
            creditsUsed = response.usage.total_tokens * chargeRate;
            deductCredits();
            setResult(response.choices[0].message.content);
            setLoading(false);
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              setError("Check Your Connecton");
              setLoading(false);
            } else if (err.message === "Request failed with status code 429") {
              setError(
                "Oops! something went wrong with your requests, We are working on it..  Try again later"
              );
              setLoading(false);
            } else if (err.message === "Request failed with status code 400") {
              setError(
                "You exceeded the word per request. Please reduce your words to 700 or less"
              );
              setLoading(false);
            } else {
              setError(err.message);
              setLoading(false);
            }
          });
      }
    } else {
      setError("type the text to summarize");
    }
  };

  //this function summarizes the text in key sentences
  const summarizeSentences = () => {
    setError("");
    if (prompt !== "") {
      if (prompt.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        setLoading(true);
        openai.chat.completions
          .create({
            model: "gpt-4-1106-preview",
            messages: [
              {
                role: "user",
                content: `Summarise this text in bullet points: ${prompt}`,
              },
            ],
            //prompt: `Summarise this text in bullet points: ${prompt}`,
            temperature: 0.5,
            max_tokens: 4000,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
          })
          .then((response) => {
            creditsUsed = response.usage.total_tokens * chargeRate;
            deductCredits();
            setResult(response.choices[0].message.content);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            if (err.message === "Network Error") {
              setError("Check Your Connecton");
              setLoading(false);
            } else if (err.message === "Request failed with status code 429") {
              setError(
                "Oops! something went wrong with your requests, We are working on it..  Try again later"
              );
              setLoading(false);
            } else if (err.message === "Request failed with status code 400") {
              setError(
                "You exceeded the word per request. Please reduce your words to 700 or less"
              );
              setLoading(false);
            } else {
              setError(err.message);
              setLoading(false);
            }
          });
      }
    } else {
      setError("type the text to summarize");
    }
  };

  //this function clears the textarea
  function deleteButton() {
    setResult("");
    setPrompt("");
    setIsButtonVisible(true);
  }

  const handleTextChange = (e) => {
    setPrompt(e.target.value);
    if (e.target.value.split(" ").length > 1500) {
      setError(
        "You exceeded the word per request. Please reduce your words to 1500 or less"
      );
    } else {
      setError("");
    }
  };

  const Error = () => {
    // Error Component
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };

  return (
    <>
      {insufficientCredits ? (
        <CreditAlert
          logedUser={logedUser}
          setInsufficientCredits={setInsufficientCredits}
        />
      ) : (
        <>
          <div className="second-header-cont">
            <FormControl className="second-header">
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedButton}
                onChange={handleChangeButton}
                className="select"
                style={{ color: "#fff" }}
              >
                <MenuItem className="select-item" value={"summarizer"}>
                  Summarizer
                </MenuItem>
                <MenuItem
                  className="select-item"
                  value={"paraphraser"}
                  onClick={redirectToPharaphraser}
                >
                  Humanizer
                </MenuItem>
                <MenuItem
                  className="select-item"
                  value={"grammachecker"}
                  onClick={redirectToGrammaCheck}
                >
                  Grammar Checker
                </MenuItem>
                <MenuItem
                  className="select-item"
                  value={"plagiarismchecker"}
                  onClick={redirectToPlagcheck}
                >
                  Plagiarism Checker
                </MenuItem>
                {/* <MenuItem className="select-item" value={'contentwriter'}>Content Writer</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="main">
            <div className="cont-header">
              {" "}
              {/*this header shows only on desktop view*/}
              <h2 className="tone">Summarizer</h2>
              <div className="options">
                <button className="option-button" onClick={summarizeParagraph}>
                  Paragraph
                </button>
                <button className="option-button" onClick={summarizeSentences}>
                  Key Sentences
                </button>
              </div>
              <div className="summary-options" style={{ display: "none" }}>
                <div className="summary-length">
                  <h6>Summary Length</h6>
                  <p>Short</p>
                  <div className="scale">
                    <div className="rate"></div>
                    <div className="point"></div>
                  </div>
                  <p>Long</p>
                </div>
              </div>
              <i
                className="bi bi-trash3 summary-trash-icon"
                onClick={deleteButton}
              ></i>
            </div>
            <div className="cont-header2">
              {" "}
              {/*this header shows only on mobile view*/}
              <div className="cont-subheader">
                <div className="subheader-texts">
                  <h5 className="subheader-text">Modes:</h5>
                  <h5 className="subheader-text">Length:</h5>
                  <div className="subheader-bar">
                    <div className="bar-length"></div>
                  </div>
                </div>
                <div className="subheader-buttons">
                  <button
                    className="subheader-button"
                    onClick={summarizeParagraph}
                  >
                    Paragraph
                  </button>
                  <button
                    className="subheader-button"
                    onClick={summarizeSentences}
                  >
                    Key Sentences
                  </button>
                </div>
              </div>
              <i
                className="bi bi-trash3 summary-trash-icon"
                onClick={deleteButton}
              ></i>
            </div>
            <div className="summary-modals">
              <div className="modal-cont">
                <button
                  className="summary-modal"
                  onClick={() => setModal("original")}
                >
                  Original
                </button>
                {modal === "original" && <div className="modal-border"></div>}
              </div>
              <div className="modal-cont">
                <button
                  className="summary-modal"
                  onClick={() => setModal("summarized")}
                >
                  Summarized
                </button>
                {modal === "summarized" && <div className="modal-border"></div>}
              </div>
            </div>
            <div className="summarizer-body body">
              {" "}
              {/* body on desktop view */}
              <div className="query">
                <div className="texts">
                  <Error />
                  {isButtonVisible && (
                    <div>
                      <textarea
                        className="textarea"
                        placeholder="Paste or write about your topic, then click summarize"
                        rows={100}
                        onChange={handleTextChange}
                        style={{
                          width: "100%",
                          minHeight: "240px",
                          maxHeight: "240px",
                          border: "none",
                          outline: "none",
                        }}
                      ></textarea>
                      {prompt === "" && (
                        <button className="paste-btn" onClick={handleClick}>
                          <i className="bi bi-clipboard" />
                          <h5>Paste Text</h5>
                        </button>
                      )}
                    </div>
                  )}
                  {!isButtonVisible && (
                    <textarea
                      onChange={handleTextChange}
                      value={prompt.trim()}
                      style={{
                        width: "100%",
                        minHeight: "290px",
                        maxHeight: "290px",
                        border: "none",
                        outline: "none",
                      }}
                      id="textArea"
                    />
                  )}
                </div>
                <div className="buttons">
                  {prompt === "" ? (
                    <button className="upload-btn">
                      <i
                        className="bi bi-file-earmark-arrow-up"
                        style={{ display: "none" }}
                      />
                      <h5 style={{ display: "none" }}>Upload Doc</h5>
                    </button>
                  ) : (
                    <h5 className="word-count"> {countWords()}/ 1500 Words</h5>
                  )}
                  <button className="pharaphrase-btn" onClick={summarize}>
                    Summarize
                  </button>
                </div>
              </div>
              {/* <Divider className='divider' style={{height: '5px', backgroundColor: '#777'}}/> */}
              <div className="result">
                {result === "" ? (
                  <>
                    <div className="empty-result">
                      <div className="a"></div>
                      <div className="b"></div>
                      <div className="c"></div>
                      <div className="d"></div>
                    </div>
                    <button className="btn2" onClick={summarize}>
                      Summarize
                    </button>
                  </>
                ) : (
                  <div className="result-ok">
                    <div className="result-text">
                      <textarea
                        readOnly
                        /*onChange={(e) => setPrompt(e.target.value)}*/
                        value={result.trim()}
                        style={{
                          cursor: "text",
                          width: "100%",
                          minHeight: "320px",
                          maxHeight: "350px",
                          border: "none",
                          outline: "none",
                        }}
                        id="textArea"
                      />
                    </div>
                    <div className="result-actions">
                      <p className="result-length">
                        {resultWordCounts()} words
                      </p>
                      <div className="result-buttons">
                        <button
                          className="result-btn summarize-result"
                          onClick={redirectToPharaphraser}
                        >
                          Humanize
                        </button>
                        <button
                          className="result-btn check-result"
                          onClick={redirectToPlagcheck}
                        >
                          Check Plagiarism
                        </button>
                        <button
                          className="result-btn gramma-result"
                          onClick={redirectToGrammaCheck}
                        >
                          Grammar check
                        </button>
                      </div>
                      <div className="result-todo">
                        <i
                          className="bi bi-download download-result"
                          onClick={downloadStringAsFile}
                        />
                        <i
                          className="bi bi-clipboard copy-result"
                          onClick={copyContent}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mobile-body">
              {" "}
              {/* body on mobile view */}
              {modal === "original" && (
                <div className="query">
                  <div className="texts">
                    <Error />
                    {isButtonVisible && (
                      <div>
                        <textarea
                          className="textarea"
                          placeholder="Paste or write about your topic, then click summarize"
                          // rows={100}
                          value={location?.state?.prompt}
                          onChange={handleTextChange}
                          style={{
                            width: "100%",
                            minHeight: "200px",
                            border: "none",
                          }}
                        ></textarea>
                        {prompt === "" && (
                          <button className="paste-btn" onClick={handleClick}>
                            <i className="bi bi-clipboard" />
                            <h5>Paste Text</h5>
                          </button>
                        )}
                      </div>
                    )}
                    {!isButtonVisible && (
                      <textarea
                        onChange={handleTextChange}
                        value={prompt}
                        style={{
                          width: "100%",
                          minHeight: "400px",
                          border: "none",
                        }}
                        id="textArea"
                      />
                    )}
                  </div>
                  <div className="buttons">
                    {prompt === "" ? (
                      <button className="upload-btn">
                        <i
                          className="bi bi-file-earmark-arrow-up"
                          style={{ display: "none" }}
                        />
                        <h5 style={{ display: "none" }}>Upload Doc</h5>
                      </button>
                    ) : (
                      <h5 className="word-count">
                        {" "}
                        {countWords()}/ 1500 Words
                      </h5>
                    )}
                    <button className="pharaphrase-btn" onClick={summarize}>
                      Summarize
                    </button>
                  </div>
                  <button className="btn2" onClick={summarize}>
                    Summarize
                  </button>
                </div>
              )}
              {modal === "summarized" && (
                <div className="result">
                  {result === "" ? (
                    <>
                      <div className="empty-result">
                        <div className="a"></div>
                        <div className="b"></div>
                        <div className="c"></div>
                        <div className="d"></div>
                      </div>
                    </>
                  ) : (
                    <div className="result-ok">
                      <div className="result-text">
                        <textarea
                          readOnly
                          /*onChange={(e) => setPrompt(e.target.value)}*/
                          value={result.trim()}
                          style={{
                            cursor: "text",
                            width: "100%",
                            minHeight: "230px",
                            maxHeight: "230px",
                            border: "none",
                            overflowY: "scroll",
                            outline: "none",
                          }}
                          id="textArea"
                        />
                      </div>
                      <div className="result-actions">
                        <p className="result-length">
                          {resultWordCounts() + "words"}
                        </p>
                        <div className="result-buttons">
                          <button
                            className="result-btn summarize-result"
                            onClick={redirectToPharaphraser}
                          >
                            Humanize
                          </button>
                          <button
                            className="result-btn check-result"
                            onClick={redirectToPlagcheck}
                          >
                            Plagiarism
                          </button>
                          <button
                            className="result-btn gramma-result"
                            onClick={redirectToGrammaCheck}
                          >
                            Grammar
                          </button>
                        </div>
                        <div className="result-todo">
                          <i
                            className="bi bi-download download-result"
                            onClick={downloadStringAsFile}
                          />
                          <i
                            className="bi bi-clipboard copy-result"
                            onClick={copyContent}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {result !== "" && (
              <div className="mobile-result-todo">
                {modal === "original" && (
                  <h4 className="word-count"> {countWords()} Words</h4>
                )}
                {modal === "summarized" && (
                  <h4 className="word-count"> {resultWordCounts()} Words</h4>
                )}
                {/* <button className='rephrase-btn' onClick={summarize} style={{display:'none'}}>Summarize</button> */}
                {modal === "summarized" && (
                  <div className="mobile-result-actions">
                    <i
                      className="bi bi-download download-result"
                      onClick={downloadStringAsFile}
                    />
                    <i
                      className="bi bi-clipboard copy-result"
                      onClick={copyContent}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {loading && (
        <div className="loader-page">
          <CircularProgress className="loader" />
          <p>Procesing Your Text, Please Wait</p>
        </div>
      )}
    </>
  );
};

export default Summarizer;

import "../Assets/Styles/Translator.css";
import { CircularProgress, Alert, Button, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ref, update } from "@firebase/database";
import { useLocation } from "react-router-dom";
import { auth, db } from "../Config/FirebaseConfig";
import { saveAs } from "file-saver";
import OpenAI from "openai";
import CreditAlert from "../Components/CreditAlert";
import SVG from "../Assets/Icons/Vector.svg";

const Translator = ({ logedUser, OPENAI_API_KEY, chargeRate }) => {
  const location = useLocation();
  const [inputText, setInputText] = useState("English");
  const [translatedText, setTranslatedText] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const textAreaRef = useRef();
  let creditsUsed = 0;

  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const [prompt, setPrompt] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    if (location.state !== null) {
      setPrompt(location?.state?.prompt);
      setIsButtonVisible(false);
    }
  }, []);

  //this function clears the textarea
  function deleteButton() {
    setTranslatedText("");
    setPrompt("");
    setIsButtonVisible(true);
    setTargetLanguage("");
    setInputText("English");
  }

  //this function paste the text in clipboard
  const handleClick = async () => {
    const clipboardText = await navigator.clipboard.readText();
    if (clipboardText) {
      setPrompt(clipboardText);
      setIsButtonVisible(false);
    }
  };

  //check user available credits
  const userCredits = logedUser?.balance;
  const deductCredits = () => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed;
      update(ref(db, `Users/${auth.currentUser.uid}`), {
        // firebase function to update the user in the database
        balance: newBalance,
      });
    } else {
      setInsufficientCredits(true);
      // alert('insufficient credits')
    }
  };
  //this is where translation happens
  const translate = () => {
    setTranslatedText("");
    setError("");
    if (prompt !== "" && targetLanguage !== "") {
      if (prompt.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        setLoading(true);
        //check user available credits
        if (userCredits >= prompt.split(" ").length * chargeRate) {
          setInsufficientCredits(false);
          //this checks if a language is not selected
          if (targetLanguage !== "") {
            openai.chat.completions
              .create({
                model: "gpt-4-1106-preview",
                messages: [
                  {
                    role: "user",
                    content: `Translate the following ${inputText} text to ${targetLanguage}: ${prompt}`,
                  },
                ],
                temperature: 0.5,
                max_tokens: 4000,
                top_p: 1,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
                format: "html",
              })
              .then((response) => {
                creditsUsed = response.usage.total_tokens * chargeRate;
                deductCredits();
                setTranslatedText(response.choices[0].message.content);
                setLoading(false);
              })
              .catch((err) => {
                if (err.message === "Network Error") {
                  setError("Check Your Connecton");
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 429"
                ) {
                  setError(
                    "Oops! something went wrong with your requests, We are working on it..  Try again later"
                  );
                  setLoading(false);
                } else if (
                  err.message === "Request failed with status code 400"
                ) {
                  setError(
                    "You exceeded the word per request. Please reduce your words to 1500 or less"
                  );
                  setLoading(false);
                } else {
                  setError(err.message);
                  setLoading(false);
                }
              });
          }
        } else {
          setInsufficientCredits(true);
          setLoading(false);
        }
      }
    } else {
      setError("type the text to translate and your desired target Language");
    }
  };

  //the functions below gets doc from device and read it
  const fileInputRef = useRef();

  function handleButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  }

  function handleFileRead(event) {
    const content = event.target.result;
    setPrompt(content);
    setIsButtonVisible(false);
  }

  //this function count words in the textarea
  function countWords() {
    return prompt.split(" ").length;
  }
  //this function count result words
  function countResultWords() {
    return targetLanguage.split(" ").length;
  }

  const handleTextChange = (e) => {
    setPrompt(e.target.value);
    if (e.target.value.split(" ").length > 1500) {
      setError(
        "You exceeded the limit of words per request. Please reduce your words to 1500 or less"
      );
    } else {
      setError("");
    }
  };

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(translatedText);
      alert("Text copied");
    } catch (err) {
      alert("Failed to copy: ", err.message);
    }
  };

  //this function downloads text to storage
  const randomNumber = Math.random() * 100;
  function downloadStringAsFile() {
    const blob = new Blob([translatedText.trim()], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, randomNumber);
  }

  const Error = () => {
    // Error Component
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };

  return (
    <>
      <div className="main">
        <div className="translator-header">
          <div className="language">
            <h5 className="h3-bold">From: </h5>
            <TextField
              label="Enter from language"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              // fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: false, // Keeps the bottom border as an underline
              }}
            />
          </div>
          <div className="language">
            <h5 className="h3-bold">To: </h5>
            <TextField
              label="Enter target language"
              value={targetLanguage}
              onChange={(e) => setTargetLanguage(e.target.value)}
              // fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: false,
              }}
            />
          </div>
          <img src={SVG} alt="trash icon" onClick={deleteButton} />
        </div>

        <div className="body">
          {insufficientCredits ? (
            <CreditAlert
              logedUser={logedUser}
              setInsufficientCredits={setInsufficientCredits}
            />
          ) : (
            <>
              <div className="query">
                <Error />
                <div className="texts">
                  {isButtonVisible && (
                    <div>
                      <textarea
                        ref={textAreaRef}
                        // onChange={handleTextChange}
                        value={prompt.trim()}
                        style={{
                          width: "100%",
                          minHeight: "249px",
                          maxHeight: "240px",
                          border: "none",
                          outline: "none",
                        }}
                        onChange={handleTextChange}
                      ></textarea>
                      {prompt === "" && (
                        <button className="paste-btn" onClick={handleClick}>
                          <i className="bi bi-clipboard" />
                          <h5>Paste Text</h5>
                        </button>
                      )}
                    </div>
                  )}
                  {!isButtonVisible && (
                    <textarea
                      ref={textAreaRef}
                      onChange={handleTextChange}
                      value={prompt.trim()}
                      style={{
                        width: "100%",
                        minHeight: "320px",
                        maxHeight: "350px",
                        border: "none",
                        outline: "none",
                      }}
                      id="textArea"
                    />
                  )}
                </div>
                <div className="buttons">
                  {prompt === "" ? (
                    <button className="upload-btn">
                      <i
                        className="bi bi-file-earmark-arrow-up"
                        onClick={handleButtonClick}
                      />
                      <h5 onClick={handleButtonClick}>Upload Doc</h5>
                      <input
                        type="file"
                        accept=".txt"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        style={{ display: "none" }}
                      />
                    </button>
                  ) : (
                    <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
                  )}
                  <button className="pharaphrase-btn" onClick={translate}>
                    Translate
                  </button>
                </div>
              </div>
              <div className="wordcount-div">
                <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
              </div>
              <div className="result">
                {translatedText === "" ? (
                  <div className="empty-result">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                  </div>
                ) : (
                  <div className="result-ok">
                    <div className="result-text">
                      <textarea
                        readOnly
                        className="result-text"
                        // onChange={(e) => setPrompt(e.target.value)}
                        value={translatedText.trim()}
                        style={{
                          cursor: "text",
                          width: "100%",
                          minHeight: "320px",
                          maxHeight: "350px",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className="paraph-result-actions">
                      <p className="result-length" style={{ display: "none" }}>
                        {countResultWords()} words
                      </p>
                      <div className="result-buttons"></div>
                      <div className="result-todo">
                        <i
                          className="bi bi-download download-result"
                          onClick={downloadStringAsFile}
                        />
                        <i
                          className="bi bi-clipboard copy-result"
                          onClick={copyContent}
                        />
                      </div>
                    </div>
                    {translatedText !== "" && (
                      <div className="mobile-result-todo">
                        <h4 className="word-count">
                          {" "}
                          {countResultWords()} Words
                        </h4>
                        <button className="rephrase-btn" onClick={translate}>
                          Translate
                        </button>
                        <div className="mobile-result-actions">
                          <i
                            className="bi bi-download download-result"
                            onClick={downloadStringAsFile}
                          />
                          <i
                            className="bi bi-clipboard copy-result"
                            onClick={copyContent}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {translatedText === "" && (
                <button className="btn2" onClick={translate}>
                  Translate
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {loading && (
        <div className="loader-page">
          <CircularProgress className="loader" />
          <p>
            Making the magic... <br />
            Please Wait
          </p>
        </div>
      )}
    </>
  );
};

export default Translator;

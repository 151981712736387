import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Alert } from "@mui/material";
import logo from "../Assets/logo.png";
import background from "../Assets/background.png";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import { db, auth } from "../Config/FirebaseConfig";
import ReactPixel from "react-facebook-pixel";

const useStyles = makeStyles({
  signupPage: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#eee",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  signupForm: {
    width: "40%",
    height: "38em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "7px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
    "@media (max-width: 800px)": {
      width: "100%",
    },
  },
  error: {
    color: "red",
  },
  heading: {
    color: "#000929",
    fontSize: "1.5rem",
    textTransform: "capitalize",
    fontFamily: "poppins",
  },
  welcomeImg: {
    width: "100px",
    height: "90px",
    backgroundColor: "transparent",
  },
  signupText2: {
    fontSize: "18px",
    fontFamily: "poppins",
    margin: "10px",
    "@media (max-width: 500px)": {
      textAlign: "center",
    },
  },
  form: {
    width: "90%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  input: {
    width: "90%",
    margin: ".7rem",
    padding: ".5rem",
  },
  link: {
    alignSelf: "flex-start",
    margin: "10px 20px",
    color: "#000",
    textDecoration: "none",
    fontSize: "18px",
    "@media (max-width: 500px)": {
      fontSize: "13px",
    },
  },
  button: {
    width: "90%",
    margin: "5px",
    padding: "10px",
    fontSize: "15px",
    borderRadius: "5px",
    backgroundColor: "#1177BB",
    color: "#ffffff",
  },
});

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [balance, setBalance] = useState(0);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  // PassWord Visibility States
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const classes = useStyles();
  let navigate = useNavigate();

  const validateInfo = () => {
    //check if the fields are not empty and phone number is only 11 digits
    let isValid = true;
    if (password === "" || email === "" || userName === "") {
      isValid = false;
      setError("All Fields Must Not Be Empty");
    }
    if (checked === false) {
      isValid = false;
      setError("You must accept terms and conditions");
    }
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();
    setError("");
    if (validateInfo()) {
      setLoading(true);
      // Create a new user with email and password using firebase
      createUserWithEmailAndPassword(auth, email.trim(), password)
        .then(() => {
          const userMail = auth.currentUser.email;
          //send verification email to the registered email
          sendEmailVerification(auth.currentUser)
            .then(() => {
              const verified = auth.currentUser.emailVerified;
              const userId = auth.currentUser.uid;
              set(ref(db, `Users/${auth.currentUser.uid}`), {
                // firebase function to add the user to the database
                email: userMail,
                password: password,
                userName: userName,
                verified: verified,
                userId: userId,
                balance: balance,
              });
              ReactPixel.track("CompleteRegistration", {
                // Track the signup event
                email: userMail,
                userName: userName,
              });
              //navigate the user to the login page after the informatio uploaded
              navigate("/verifyemail");
            })
            .catch((err) => {
              // handles the error to be shown to the user
              setLoading(false);
              if (err.message === "auth/invalid-email") {
                setError("Enter a valid Email Address");
              } else if (
                err.message === "Firebase: Error (auth/network-request-failed)."
              ) {
                setError("Network request failed, check your connection");
              } else {
                setError("Something went wrong, please retry");
              }
            });
        })
        .catch((err) => {
          // Handle Errors
          if (err.message === "Firebase: Error (auth/invalid-email).") {
            setError("Enter a valid Email Address");
          } else if (
            err.message === "Firebase: Error (auth/network-request-failed)."
          ) {
            setError("Network request failed, check your connection");
          } else if (err.message === "Firebase: Error (auth/email-exist).") {
            setError("This email is already registered");
          } else if (
            err.message === "Firebase: Error (auth/email-already-in-use)."
          ) {
            setError("This email is already in use");
          } else {
            setError("Something went wrong, please retry");
          }
        });
    }
  };
  const Error = () => {
    // Eroor Component
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };
  return (
    <div className={classes.signupPage}>
      <div className={classes.signupForm}>
        <img src={logo} alt="Retexter" className={classes.welcomeImg} />
        <h3 className={classes.heading}>Sign Up</h3>
        <h3 className={classes.signupText2}>
          Empower your content with AI's magic touch
        </h3>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.form}
        >
          <Error />
          <TextField
            className={classes.input}
            id="name"
            label="Name"
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
            required
          />
          <TextField
            className={classes.input}
            id="outlined-basic email"
            label="Email Address"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            className={classes.input}
            id="password"
            label="Password"
            required
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  color="primary"
                  label
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={
                <div style={{ display: "flex", alignContent: "center" }}>
                  <p style={{ margin: "auto" }}>I agree with </p>
                  <NavLink
                    to="http://retexter.ai/terms"
                    target={"_blank"}
                    style={{ margin: "auto 3px", textDecoration: "none" }}
                  >
                    terms and conditions
                  </NavLink>
                </div>
              }
              labelPlacement="end"
            />
          </FormControl>
          {loading ? (
            <Button variant="disabled" className={classes.button}>
              Signing up...
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={register}
              className={classes.button}
            >
              Continue
            </Button>
          )}
          <NavLink className={classes.link} to="/login">
            Already have Account?
            <span>
              <b> Log in</b>
            </span>
          </NavLink>
        </Box>
      </div>
    </div>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";
import "../Assets/Styles/GrammaCheck.css";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@mui/material/Select";
import OpenAI from "openai";
import { Alert, CircularProgress } from "@mui/material";
import { ref, update } from "@firebase/database";
import { auth, db } from "../Config/FirebaseConfig";
import CreditAlert from "../Components/CreditAlert";

const GrammaCheck = ({ logedUser, OPENAI_API_KEY, chargeRate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState("grammachecker");
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const userCredits = logedUser?.balance;
  let creditsUsed = 0;

  //THIS USEEFFECT CHECK IF THE PAGE WAS REDIRECTED FROM ANOTHER PAGE AND SET THE TEXT TO DATA RECIEVED
  useEffect(() => {
    if (location.state !== null) {
      setText(location?.state?.prompt);
      setIsButtonVisible(false);
    }
  }, []);

  //this function paste the text in clipboard
  const pasteText = async () => {
    const clipboardText = await navigator.clipboard.readText();
    if (clipboardText) {
      setText(clipboardText);
      setIsButtonVisible(false);
      //setIsButtonVisible(false);
    }
  };

  const redirectToPharaphraser = () => {
    navigate("/paraphraser", { state: { prompt: text } });
  };
  const redirectToPlagcheck = () => {
    navigate("/check-plagiarism", { state: { prompt: text } });
  };
  const redirectToSummarizer = () => {
    navigate("/summarizer", { state: { prompt: text } });
  };

  //this function copies text to the clipboard
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(text2 !== "" ? text2 : text);
      alert("Text copied");
    } catch (err) {
      alert(err.message);
    }
  };

  //this function count words in the textarea
  function countWords() {
    return text.split(" ").length;
  }

  const handleChangeButton = (e) => {
    setSelectedButton(e.target.value);
  };

  const configuration = {
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  };
  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const deductCredits = () => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed;
      update(ref(db, `Users/${auth.currentUser.uid}`), {
        // firebase function to update the user in the database
        balance: newBalance,
      });
    } else {
      setInsufficientCredits(true);
    }
  };

  const fixErrors = async () => {
    setError("");
    if (text !== "") {
      if (text.split(" ").length > 1500) {
        setError("Maximum of 1500 Words Exceeded");
      } else {
        //check user available credits
        if (userCredits >= text.split(" ").length * chargeRate) {
          setInsufficientCredits(false);
          setLoading(true);
          try {
            const response = await openai.chat.completions.create({
              model: "gpt-4-1106-preview",
              messages: [
                {
                  role: "user",
                  content: `fix all grammatical errors and misspelled words in the following: ${text}`,
                },
              ],
              //prompt: `fix all grammatical errors and misspelled words in the following: ${text}`,
              temperature: 0,
              max_tokens: 4000,
              top_p: 1,
              frequency_penalty: 0.0,
              presence_penalty: 0.0,
            });
            creditsUsed = response.usage.total_tokens * chargeRate;
            deductCredits();
            setText2(response.choices[0].message.content);
            setLoading(false);
          } catch (err) {
            if (err.message === "Network Error") {
              setError("Check Your Connecton");
              setLoading(false);
            } else if (err.message === "Request failed with status code 429") {
              setError(
                "Oops! something went wrong with your requests, We are working on it..  Try again later"
              );
              setLoading(false);
            } else if (err.message === "Request failed with status code 400") {
              setError(
                "You exceeded the word per request. Please reduce your words to 700 or less"
              );
              setLoading(false);
            } else {
              setError(err.message);
              setLoading(false);
            }
          }
        } else {
          setInsufficientCredits(true);
          setLoading(false);
        }
      }
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    if (e.target.value.split(" ").length > 1500) {
      setError("Reduce the Number Of Words To Proceed");
    } else {
      setError("");
    }
  };

  function countResultWords() {
    return text2.split(" ").length;
  }

  const Error = () => {
    // Error Component
    if (error !== "") {
      return <Alert severity="error">{error}</Alert>;
    }
  };

  return (
    <>
      <div className="second-header-cont">
        <FormControl className="second-header">
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedButton}
            onChange={handleChangeButton}
            className="select"
            style={{ color: "#fff" }}
          >
            <MenuItem className="select-item" value={"grammachecker"}>
              Grammar Checker
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"summarizer"}
              onClick={redirectToSummarizer}
            >
              Summarizer
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"paraphraser"}
              onClick={redirectToPharaphraser}
            >
              Humanizer
            </MenuItem>
            <MenuItem
              className="select-item"
              value={"plagiarismchecker"}
              onClick={redirectToPlagcheck}
            >
              Plagiarism Checker
            </MenuItem>
            {/* <MenuItem className="select-item" value={'contentwriter'}>Content Writer</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className="main">
        <div className="body">
          {insufficientCredits ? (
            <CreditAlert
              logedUser={logedUser}
              setInsufficientCredits={setInsufficientCredits}
            />
          ) : (
            <>
              <div className="query">
                <Error />
                <div className="texts">
                  {isButtonVisible && (
                    <div>
                      <textarea
                        placeholder="Start by writing or pasting your text here, then click Fix Errors"
                        style={{
                          width: "100%",
                          minHeight: "249px",
                          maxHeight: "240px",
                          border: "none",
                          outline: "none",
                        }}
                        onChange={handleTextChange}
                      ></textarea>
                      {text === "" && (
                        <button className="paste-btn" onClick={pasteText}>
                          <i className="bi bi-clipboard" />
                          <h5>Paste Text</h5>
                        </button>
                      )}
                    </div>
                  )}
                  {!isButtonVisible && (
                    <textarea
                      // ref={textAreaRef}
                      onChange={handleTextChange}
                      value={text.trim()}
                      style={{
                        width: "100%",
                        minHeight: "320px",
                        maxHeight: "350px",
                        border: "none",
                        outline: "none",
                      }}
                      id="textArea"
                    />
                  )}
                </div>
                <div className="buttons">
                  <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
                  <button className="pharaphrase-btn" onClick={fixErrors}>
                    Fix Errors
                  </button>
                </div>
                <div className="wordcount-div">
                  <h4 className="word-count"> {countWords()}/ 1500 Words</h4>
                </div>
                {text2 === "" && (
                  <button className="btn2" onClick={fixErrors}>
                    Fix Errors
                  </button>
                )}
              </div>
              <div className="result">
                {text2 === "" ? (
                  <div className="empty-result">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                  </div>
                ) : (
                  <div className="result-ok">
                    <div className="result-text">
                      <textarea
                        readOnly
                        className="result-text"
                        // onChange={(e) => setPrompt(e.target.value)}
                        value={text2.trim()}
                        style={{
                          cursor: "text",
                          width: "100%",
                          minHeight: "320px",
                          maxHeight: "350px",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </div>
                    <div className="paraph-result-actions">
                      <p className="result-length" style={{ display: "none" }}>
                        {countResultWords()} words
                      </p>
                      <div className="result-buttons">
                        <button
                          className="result-btn summarize-result"
                          onClick={redirectToSummarizer}
                        >
                          Summarize
                        </button>
                        <button
                          className="result-btn check-result"
                          onClick={redirectToPlagcheck}
                        >
                          Plagiarism
                        </button>
                        <button
                          className="result-btn gramma-result"
                          onClick={redirectToPharaphraser}
                        >
                          Humanize
                        </button>
                      </div>
                      <div className="result-todo">
                        {/* <i className="bi bi-download download-result" onClick={downloadStringAsFile}/> */}
                        <i
                          className="bi bi-clipboard copy-result"
                          onClick={copyContent}
                        />
                      </div>
                    </div>
                    {text2 !== "" && (
                      <div className="mobile-result-todo">
                        <h4 className="word-count">
                          {" "}
                          {countResultWords()} Words
                        </h4>
                        <button className="rephrase-btn" onClick={fixErrors}>
                          Fix Errors
                        </button>
                        <div className="mobile-result-actions">
                          {/* <i className="bi bi-download download-result" onClick={downloadStringAsFile} /> */}
                          <i
                            className="bi bi-clipboard copy-result"
                            onClick={copyContent}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {loading && (
        <div className="loader-page">
          <CircularProgress className="loader" />
          <p>
            Making the magic... <br />
            Please Wait
          </p>
        </div>
      )}
    </>
  );
};

export default GrammaCheck;
